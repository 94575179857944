import * as Sentry from "@sentry/sveltekit";

Sentry.init({
  dsn: "https://f673b1a6f7f845218ca3987bf1992a8a@o4505290215587840.ingest.sentry.io/4505290218274816",
  tracesSampleRate: 1.0,
  integrations: [new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const myErrorHandler = ({ error, event }) => {
  console.error("An error occurred on the client side:", error, event);
};

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler);